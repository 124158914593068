import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaStar } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Wrap Wizardry
			</title>
			<meta name={"description"} content={"Їдьте стильно - відчуйте магію обгортання Wrap Wizardry!"} />
			<meta property={"og:title"} content={"Головна | Wrap Wizardry"} />
			<meta property={"og:description"} content={"Їдьте стильно - відчуйте магію обгортання Wrap Wizardry!"} />
			<meta property={"og:image"} content={"https://britesside.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://britesside.com/img/2555013.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://britesside.com/img/2555013.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://britesside.com/img/2555013.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://britesside.com/img/2555013.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://britesside.com/img/2555013.png"} />
			<meta name={"msapplication-TileImage"} content={"https://britesside.com/img/2555013.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="160px 0 170px 0" background="linear-gradient(0deg,rgba(255, 255, 255, 0.58) 0%,rgba(255, 255, 255, 0.6) 100%),rgba(0, 0, 0, 0) url(https://britesside.com/img/1.jpg) 50% 50% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-9">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
				>
					Wrap Wizardry – ваше стильне авто
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#50555a"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Завітайте до світу Wrap Wizardry, де на вас чекає перетворення вашого автомобіля. Завдяки першокласній майстерності та індивідуальному стилю, наші вінілові обклеювання не просто змінюють колір вашого автомобіля - вони покращують його загальну привабливість. Незалежно від того, чи прагнете ви витонченої елегантності або сміливої заяви, ми допоможемо втілити ваше бачення в реальність.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 48px 12px 48px"
						background="--color-primary"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						hover-background="rgba(63, 36, 216, 0)"
						hover-color="--darkL1"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Відвідати нас
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Чому варто обрати Wrap Wizardry?
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaStar}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Точне нанесення: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Наші обгортання наносяться з абсолютною точністю сертифікованими фахівцями, забезпечуючи ідеальне вирівнювання та завершення.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaStar}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Матеріали преміум-класу: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ми використовуємо лише вініл найвищої якості для створення міцних, яскравих обгорток, які витримують випробування часом.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaStar}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Творчість на замовлення: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ваша уява не має меж - наші дизайнери спеціалізуються на створенні індивідуальних дизайнів, які відображають ваш особистий стиль.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://britesside.com/img/2.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					Заснована на пристрасті до автомобільної краси, компанія Wrap Wizardry прагне забезпечити виняткову якість та сервіс в обгортанні автомобілів вінілом. Наш цех оснащений найсучаснішими інструментами та укомплектований експертами, які так само захоплені автомобілями, як і ви. Ми прагнемо, щоб клієнтський досвід був таким же бездоганним і задоволеним, як і приголомшлива обробка, яку ми наносимо на ваші транспортні засоби.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
				justify-content="space-between"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
					Відчуйте магію перетворення з Wrap Wizardry. Завітайте до нас і дозвольте своєму автомобілю розпочати шлях до відмінності!
					</Text>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://britesside.com/img/3.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" padding="65px 0 65px 0" quarkly-title="Images-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
				>
					<Image
						left={0}
						min-height="100%"
						top={0}
						right={0}
						bottom={0}
						src="https://britesside.com/img/4.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://britesside.com/img/5.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						position="absolute"
						display="block"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
			>
				<Box
					width="100%"
					overflow-x="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top="auto"
						src="https://britesside.com/img/6.jpg"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="25%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					lg-text-align="center"
					sm-margin="30px 0px 0px 0px"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					md-text-align="left"
				>
					Їдьте стильно - відчуйте магію обгортання Wrap Wizardry!
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});